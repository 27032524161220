*,
*:after,
*:before {
  outline: none;
}

.input {
  background: white;
}

.logo {
  background: rgb(241, 241, 242);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);

  img {
    width: 180px;
  }
}

.wrap {
  max-width: 420px;
  margin: 0 auto;
  padding: 16px;
}

.page-wrap {
  padding-top: 38px;
}

h1 {
  font-size: 18px;
  margin: 0 0 8px;
  font-weight: bold;
  padding: 0;
}

p {
  font-size: 16px;
  margin: 0 0 16px;
}

.button-ademibank {
  background: #CC2D1A;
  color: white;

  &:disabled {
    background: rgb(188, 190, 192);
    color: white;
  }
}

.link {
  color: #248ce0;
}

.checkbox {
  flex-direction: row-reverse;
  display: flex;
  align-items: baseline;
  position: relative;
  padding-left: 8px;

  label {
    font-weight: 300;
  }

  input {
    margin-right: 8px;
    padding-right: 8px;
    padding-right: 8px;
    visibility: hidden;
    position: absolute;

    + span {
      width: 18px;
      height: 18px;
      display: block;
      margin-right: 8px;
      padding-right: 8px;
      padding-right: 8px;
      border: 1px solid #333;
      border-radius: 3px;
      top: 3px;
      position: relative;

      svg {
        position: relative;
        top: -3px;
        left: 1px;
      }
    }

    &:checked {
      + span {
        width: 18px;
        height: 18px;
        display: block;
        margin-right: 8px;
        padding-right: 8px;
        padding-right: 8px;
        border: 1px solid #CC2D1A;
        background: #CC2D1A;
      }
    }
  }
}

.react-code-input {
  display: flex !important;
  justify-content: center;
  margin: 0 -3%;

  > * {
    margin: 0 3%;
  }
}

.text-light {
  font-weight: 300;
}

.counter {
  justify-content: center;
  display: flex;
  .stopwatch {
    font-size: 32px;
    font-weight: 100;
    color: #d1d3d4;
    margin-right: 8px;
  }
  span {
    font-weight: 700;
    font-size: 32px;
  }
}

.timer {
  margin: 60px 0 0;
  text-align: center;

  &-send {
    margin-top: 70px;
  }
}

.outlined-thin {
  border: 1px solid #5f5f61;
  font-weight: normal;
  color: #5f5f61;
  display: inline-block;
  width: auto;
}

.big-image {
  max-width: 250px;
  margin: 100px auto 0;
}

.loading {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 64px;
}
