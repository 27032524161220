@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
.positive {
  color: #359b3c; }
  .positive-bg {
    background: #359b3c; }

.negative {
  color: #cd283c; }
  .negative-bg {
    background: #cd283c; }

.info {
  color: #248ce0; }
  .info-bg {
    background: #248ce0; }

.warning {
  color: #c9711e; }
  .warning-bg {
    background: #c9711e; }

hr {
  border: 1px solid #d3d3d3; }

button,
a {
  cursor: pointer;
  outline: none; }

button:disabled {
  cursor: default; }

*,
*::before,
*::after {
  box-sizing: border-box; }

:root {
  font-size: "Open Sans", sans-serif, Arial; }

body {
  margin: 0;
  font-family: "Open Sans", sans-serif, Arial;
  font-size: 16px;
  font-weight: 400;
  line-height: inherit;
  color: #333333;
  background-color: #ffffff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

img {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

[role="button"] {
  cursor: pointer; }

[hidden] {
  display: none !important; }

.dark,
body.dark {
  color: #f1f1f2; }
  .dark h1,
  .dark h2,
  .dark h3,
  .dark h4,
  .dark h5,
  .dark h6,
  .dark p,
  body.dark h1,
  body.dark h2,
  body.dark h3,
  body.dark h4,
  body.dark h5,
  body.dark h6,
  body.dark p {
    color: #f1f1f2; }
  .dark .positive,
  body.dark .positive {
    color: #359b3c; }
    .dark .positive-bg,
    body.dark .positive-bg {
      background: #359b3c; }
  .dark .negative,
  body.dark .negative {
    color: #cd283c; }
    .dark .negative-bg,
    body.dark .negative-bg {
      background: #cd283c; }
  .dark .info,
  body.dark .info {
    color: #248ce0; }
    .dark .info-bg,
    body.dark .info-bg {
      background: #248ce0; }
  .dark .warning,
  body.dark .warning {
    color: #c9711e; }
    .dark .warning-bg,
    body.dark .warning-bg {
      background: #c9711e; }
  .dark .shadow, .dark .alert, .dark .card, .dark .modal,
  body.dark .shadow,
  body.dark .alert,
  body.dark .card,
  body.dark .modal {
    box-shadow: 0 5px 13px rgba(0, 0, 0, 0.79); }
  .dark .border, .dark .modal,
  body.dark .border,
  body.dark .modal {
    border: 1px solid #878787; }
  .dark .border-bottom, .dark .modal_head,
  body.dark .border-bottom,
  body.dark .modal_head {
    border-bottom: 1px solid #878787; }
  .dark .input-border, .dark .input,
  body.dark .input-border,
  body.dark .input {
    border: 1px solid #e6e7e8; }
    .dark .input-border:disabled, .dark .input:disabled,
    body.dark .input-border:disabled,
    body.dark .input:disabled {
      border-color: #6b6b6b !important; }
      .dark .input-border:disabled + .input-label, .dark .input:disabled + .input-label,
      body.dark .input-border:disabled + .input-label,
      body.dark .input:disabled + .input-label {
        color: #878787 !important; }
  .dark .background-theme, .dark .input, .dark .input:focus + .input-label:after, .dark .input.floating + .input-label:after, .dark .modal,
  body.dark .background-theme,
  body.dark .input,
  body.dark .input:focus + .input-label:after,
  body.dark .input.floating + .input-label:after,
  body.dark .modal {
    background: #333333; }
  .dark .border-theme-reverse,
  body.dark .border-theme-reverse {
    border: 1px solid #f1f1f2; }
  .dark .color-theme, .dark .table-pagination-number, .dark .table td, .table .dark td,
  body.dark .color-theme,
  body.dark .table-pagination-number,
  body.dark .table td,
  .table body.dark td {
    color: #f1f1f2; }
  .dark .input-color-theme, .dark .input,
  body.dark .input-color-theme,
  body.dark .input {
    color: #e6e7e8; }
  .dark .overlay, .dark .modal-overlay,
  body.dark .overlay,
  body.dark .modal-overlay {
    background: rgba(107, 107, 107, 0.6); }
  .dark .button-primary,
  body.dark .button-primary {
    color: #333333; }
    .dark .button-primary:focus,
    body.dark .button-primary:focus {
      border-color: #f1f1f2;
      background: #e85b4b; }
    .dark .button-primary:not(:disabled):hover,
    body.dark .button-primary:not(:disabled):hover {
      background: #e85b4b; }
    .dark .button-primary:disabled,
    body.dark .button-primary:disabled {
      background: #6b6b6b;
      color: #454545; }
  .dark .button-outline,
  body.dark .button-outline {
    border-color: #f1f1f2;
    color: #f1f1f2; }
  .dark .button-ghost,
  body.dark .button-ghost {
    color: #f1f1f2; }
  .dark .button-action, .dark .button-group button, .button-group .dark button,
  body.dark .button-action,
  body.dark .button-group button,
  .button-group body.dark button {
    border: 1px solid #e6e7e8;
    background: #454545;
    color: #e6e7e8;
    font-weight: 400; }
    .dark .button-action:hover, .dark .button-group button:hover, .button-group .dark button:hover,
    body.dark .button-action:hover,
    body.dark .button-group button:hover,
    .button-group body.dark button:hover {
      background: #59595b;
      border-color: #ffffff;
      color: #ffffff; }
    .dark .button-action:focus, .dark .button-group button:focus, .button-group .dark button:focus,
    body.dark .button-action:focus,
    body.dark .button-group button:focus,
    .button-group body.dark button:focus {
      border-color: #cc2d1a;
      background: #454545;
      color: #ffffff; }
    .dark .button-action:disabled, .dark .button-group button:disabled, .button-group .dark button:disabled,
    body.dark .button-action:disabled,
    body.dark .button-group button:disabled,
    .button-group body.dark button:disabled {
      background: #bcbec0;
      border-color: #454545;
      color: #454545; }
  .dark .button-positive,
  body.dark .button-positive {
    border-color: #4dd157;
    color: #4dd157; }
    .dark .button-positive:hover,
    body.dark .button-positive:hover {
      border-color: #4dd157;
      color: #4dd157; }
    .dark .button-positive:focus,
    body.dark .button-positive:focus {
      border-color: #4dd157;
      color: #4dd157;
      background: #454545 !important; }
  .dark .button-negative,
  body.dark .button-negative {
    border-color: #ff7075;
    color: #ff7075; }
    .dark .button-negative:hover,
    body.dark .button-negative:hover {
      border-color: #ff7075;
      color: #ff7075; }
    .dark .button-negative:focus,
    body.dark .button-negative:focus {
      border-color: #ff7075;
      color: #ff7075;
      background: #454545 !important; }
  .dark .button-info,
  body.dark .button-info {
    border-color: #43a2f7;
    color: #43a2f7; }
    .dark .button-info:hover,
    body.dark .button-info:hover {
      border-color: #43a2f7;
      color: #43a2f7; }
    .dark .button-info:focus,
    body.dark .button-info:focus {
      border-color: #43a2f7;
      color: #43a2f7;
      background: #454545 !important; }
  .dark .button-warning,
  body.dark .button-warning {
    border-color: #ef810f;
    color: #ef810f; }
    .dark .button-warning:hover,
    body.dark .button-warning:hover {
      border-color: #ef810f;
      color: #ef810f; }
    .dark .button-warning:focus,
    body.dark .button-warning:focus {
      border-color: #ef810f;
      color: #ef810f;
      background: #454545 !important; }
  .dark .has-error input:focus,
  body.dark .has-error input:focus {
    background: transparent; }
  .dark .has-success input:focus,
  body.dark .has-success input:focus {
    background: transparent; }
  .dark .has-success input,
  body.dark .has-success input {
    border-color: #346432; }
  .dark table.table th,
  body.dark table.table th {
    background: #878787;
    color: #313131; }
    .dark table.table th:not(:last-child),
    body.dark table.table th:not(:last-child) {
      border-color: #333333; }

.background-theme, .input, .input:focus + .input-label:after, .input.floating + .input-label:after, .modal {
  background: #f1f1f2; }

.border-theme-reverse {
  border: 1px solid #333333; }

.color-theme, .table-pagination-number, .table td {
  color: #333333; }

.input-color-theme, .input {
  color: #6b6b6b; }

.shadow, .alert, .card, .modal {
  box-shadow: 0 5px 13px rgba(69, 69, 70, 0.21); }

.border, .modal {
  border: 1px solid #d1d3d4; }

.border-bottom, .modal_head {
  border-bottom: 1px solid #d1d3d4; }

.input-border, .input {
  border: 1px solid #6b6b6b; }

.overlay, .modal-overlay {
  background: rgba(29, 29, 29, 0.6); }

.content {
  padding: 16px; }

.heading {
  margin-bottom: 16px; }

.m-0 {
  margin: 0 !important; }

.m-sm {
  margin: 6px; }

.m-md {
  margin: 12px; }

.m-lg {
  margin: 20px; }

.m-xl {
  margin: 24px; }

.mr-sm {
  margin-right: 6px; }

.mr-md {
  margin-right: 12px; }

.mr-lg {
  margin-right: 20px; }

.mr-xl {
  margin-right: 24px; }

.mb-sm {
  margin-bottom: 6px; }

.mb-md {
  margin-bottom: 12px; }

.mb-lg {
  margin-bottom: 20px; }

.mb-xl {
  margin-bottom: 24px; }

.mt-sm {
  margin-top: 6px; }

.mt-md {
  margin-top: 12px; }

.mt-lg {
  margin-top: 20px; }

.mt-xl {
  margin-top: 24px; }

.mb-sm {
  margin-bottom: 6px; }

.mb-md {
  margin-bottom: 12px; }

.mb-lg {
  margin-bottom: 20px; }

.mb-xl {
  margin-bottom: 24px; }

.border, .modal {
  border: 1px solid #d3d3d3; }

.dark.border, .dark.modal, .dark .border, .dark .modal {
  border: 1px solid #d3d3d3; }

.flex {
  display: flex; }

.flex-wrap {
  flex-wrap: wrap; }

.align-start {
  align-items: flex-start; }

.justify-center {
  justify-content: center; }

h1 {
  font-weight: 700;
  font-size: 32px; }

h2 {
  font-weight: 700;
  font-size: 28px; }

h3 {
  font-weight: 700;
  font-size: 25px; }

h4 {
  font-weight: 700;
  font-size: 22px; }

h5 {
  font-weight: 700;
  font-size: 20px; }

h6 {
  font-weight: 700;
  font-size: 18px; }

h1 {
  color: #333333;
  margin: 0; }

h2 {
  color: #333333;
  margin: 0; }

h3 {
  color: #333333;
  margin: 0; }

h4 {
  color: #333333;
  margin: 0; }

h5 {
  color: #333333;
  margin: 0; }

h6 {
  color: #333333;
  margin: 0; }

.display-1 {
  font-weight: 300;
  font-size: 51px; }

.display-2 {
  font-weight: 300;
  font-size: 46px; }

.display-3 {
  font-weight: 300;
  font-size: 41px; }

.display-4 {
  font-weight: 700;
  font-size: 36px; }

p {
  font-weight: 400;
  font-size: 16px;
  color: #454546; }

.body-1 {
  font-weight: 400;
  font-size: 16px;
  color: #454546; }

.body-2 {
  font-weight: 400;
  font-size: 14px;
  color: #454546; }

.subtitle-1 {
  font-weight: 600;
  font-size: 16px; }

.subtitle-2 {
  font-weight: 600;
  font-size: 14px; }

.caption {
  font-weight: 400;
  font-size: 12px; }

.overline {
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase; }

.container {
  max-width: 1366px;
  margin: 0 auto;
  background: #f1f1f2; }

.dark .container, .dark.container {
  background: #333333; }

.alert {
  border-radius: 4px;
  display: inline-block;
  overflow: hidden; }
  .alert-full {
    width: 100%; }
  .alert-positive {
    color: #ffffff;
    background: #359b3c; }
  .alert-negative {
    color: #ffffff;
    background: #cd283c; }
  .alert-info {
    color: #ffffff;
    background: #248ce0; }
  .alert-warning {
    color: #ffffff;
    background: #c9711e; }
  .alert_close {
    border: 0;
    background: transparent;
    color: #ffffff;
    padding: 0; }
    .alert_close > .fa,
    .alert_close > .fas {
      font-size: 20px;
      margin-left: 12px; }
  .alert_head {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    padding: 12px; }
    .alert_head > .fa,
    .alert_head > .fas {
      font-size: 20px;
      margin-left: 12px; }
  .alert_title {
    display: flex;
    align-items: center; }
    .alert_title > .fa,
    .alert_title > .fas {
      margin-right: 12px; }
  .alert_body {
    color: #454546;
    font-size: 14px;
    background: #ffffff;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 12px; }
  @media screen and (max-width: 700px) {
    .alert {
      width: 100%; } }

.table {
  width: 100%;
  max-width: 100%; }
  .table-tools {
    padding: 16px;
    display: flex;
    justify-content: flex-end; }
    .table-tools > * {
      margin-left: 12px; }
    @media screen and (max-width: 700px) {
      .table-tools {
        display: block; }
        .table-tools > * {
          margin-left: 0;
          margin-bottom: 12px; } }
  .table-pagination-container {
    display: flex;
    padding: 16px;
    align-items: center;
    justify-content: space-between; }
  .table-pagination-number {
    font-weight: 600; }
  .table th {
    background: #878787;
    color: #f1f1f2;
    padding: 16px;
    text-align: left; }
    .table th:not(:last-child) {
      border-right: 2px solid #f1f1f2; }
  .table td {
    text-align: left;
    padding: 16px;
    border-bottom: 1px solid #878787; }

.input {
  border-radius: 4px;
  height: 40px;
  padding: 12px 36px 12px 36px;
  font-weight: 600;
  box-shadow: none; }
  .input-sm {
    height: 32px;
    padding: 8px 36px 8px 36px; }
  .input-floating-label {
    position: relative; }
  .input-helper-text {
    display: block;
    font-size: 12px; }
  .input-label {
    transition: all 0.1s ease-in-out;
    position: absolute;
    top: 50%;
    left: 36px;
    transform: translateY(-50%); }
  .input:focus + .input-label, .input.floating + .input-label {
    padding-left: 4px;
    padding-right: 4px;
    top: 0%;
    left: 12px;
    font-size: 9px;
    position: absolute; }
    .input:focus + .input-label:after, .input.floating + .input-label:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      z-index: -1;
      top: 50%;
      left: 0; }
  .input:disabled {
    border-color: #bcbec0 !important;
    color: #bcbec0; }
    .input:disabled + .input-label {
      color: #bcbec0; }
  .input-full {
    width: 100%; }
  .input-prefix-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
    color: #bcbec0; }
  .input-suffix-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    color: #bcbec0; }
  @media screen and (max-width: 700px) {
    .input {
      width: 100%; } }

.has-error input,
.has-error .input-helper-text,
.has-error .input-suffix-icon {
  border-color: #cd283c !important; }

.has-error + .input-helper-text,
.has-error .input-suffix-icon {
  color: #cd283c; }

.has-error input.floating + .input-label {
  color: #cd283c; }

.has-error input:focus {
  background: #fbc6c6; }

.has-success input,
.has-success .input-helper-text,
.has-success .input-suffix-icon {
  border-color: #377837 !important; }

.has-success + .input-helper-text,
.has-success .input-suffix-icon {
  color: #377837; }

.has-success input.floating + .input-label {
  color: #377837; }

.has-success input:focus {
  background: #e6f5e7; }

.button, .button-group button {
  border: 2px solid transparent;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.1s ease-in-out;
  border-radius: 16px; }
  .button-icon {
    border: none;
    background: transparent;
    color: #454546;
    padding: 0; }
  .button:not([disabled]), .button-group button:not([disabled]) {
    cursor: pointer; }
  .button-block {
    width: 100%;
    display: block; }
  .button-primary {
    background: #cc2d1a;
    color: #ffffff;
    padding: 4px 12px;
    line-height: 20px; }
    .button-primary:hover {
      background: #9f2314; }
    .button-primary:active {
      background: #881e11; }
    .button-primary:focus {
      border-color: #881e11; }
    .button-primary:disabled {
      background: #bebebf; }
  .button-outline {
    border-color: #59595b;
    background: rgba(107, 107, 108, 0);
    border-radius: 24px; }
    .button-outline:hover {
      background: rgba(107, 107, 108, 0.15); }
    .button-outline:active {
      border-color: #59595b;
      background: rgba(107, 107, 108, 0.3); }
    .button-outline:focus {
      border-color: #59595b;
      border-style: dotted;
      background: rgba(107, 107, 108, 0); }
    .button-outline:disabled {
      background: rgba(107, 107, 108, 0);
      border-color: #bebebf;
      color: #bebebf; }
  .button-ghost {
    color: #6b6b6c;
    background: rgba(107, 107, 108, 0); }
    .button-ghost:hover {
      background: rgba(107, 107, 108, 0.15); }
    .button-ghost:active {
      background: rgba(107, 107, 108, 0.3); }
    .button-ghost:focus {
      border-color: #6b6b6c;
      color: #6b6b6c; }
    .button-ghost:disabled {
      background: rgba(107, 107, 108, 0);
      color: #bebebf; }
  .button-action, .button-group button {
    border-radius: 4px;
    border: 1px solid #6b6b6b;
    background: #e6e7e8;
    font-weight: 400; }
    .button-action:hover, .button-group button:hover {
      background: #d1d3d4; }
    .button-action:focus, .button-group button:focus {
      border-color: #cc2d1a;
      background: #e6e7e8; }
    .button-action:disabled, .button-group button:disabled {
      background: #bcbec0;
      border-color: #e6e7e8;
      color: #e6e7e8; }
    .button-action.button-lg, .button-group button, .button-action.button-xl, .button-group button.button-xl {
      border-radius: 4px; }
  .button-sm {
    font-size: 14px;
    padding: 0px 12px;
    line-height: 16px; }
    .button-sm i.fa,
    .button-sm i.fas {
      font-size: 14px; }
  .button-md {
    padding: 4px 12px;
    line-height: 20px; }
    .button-md i.fa,
    .button-md i.fas {
      font-size: 16px; }
  .button-lg, .button-group button {
    font-size: 18px;
    line-height: 20px;
    padding: 8px 12px;
    border-radius: 24px; }
    .button-lg i.fa, .button-group button i.fa,
    .button-lg i.fas,
    .button-group button i.fas {
      font-size: 18px; }
  .button-xl {
    font-size: 22px;
    padding: 10px 12px;
    line-height: 24px;
    border-radius: 24px; }
    .button-xl i.fa,
    .button-xl i.fas {
      font-size: 20px; }
  .button i.fa, .button-group button i.fa,
  .button i.fas,
  .button-group button i.fas {
    margin-left: 4px; }
  .button-positive {
    border-color: #377837;
    color: #377837; }
    .button-positive:hover {
      border-color: #346432;
      color: #346432; }
    .button-positive:focus {
      border-color: #377837;
      background: #d3eed3; }
  .button-negative {
    border-color: #cd283c;
    color: #cd283c; }
    .button-negative:hover {
      border-color: #a42d37;
      color: #a42d37; }
    .button-negative:focus {
      border-color: #cd283c;
      background: #fae1e1; }
  .button-info {
    border-color: #336ea6;
    color: #336ea6; }
    .button-info:hover {
      border-color: #325c85;
      color: #325c85; }
    .button-info:focus {
      border-color: #336ea6;
      background: #dce8f3; }
  .button-warning {
    border-color: #9a5c24;
    color: #9a5c24; }
    .button-warning:hover {
      border-color: #7f4e24;
      color: #7f4e24; }
    .button-warning:focus {
      border-color: #9a5c24;
      background: #fae3cf; }
  @media screen and (max-width: 700px) {
    .button, .button-group button {
      display: block;
      width: 100%; } }

.floating-button {
  position: fixed;
  top: 0;
  right: 0;
  margin: 2%;
  padding: 8px; }

.button-group {
  display: flex; }
  .button-group button {
    border: 1px solid #878787;
    border-radius: 0;
    border-left: none !important;
    color: #6b6b6b; }
    .button-group button > i {
      margin: 0 !important; }
    .button-group button:focus {
      border-color: inherit !important; }
    .button-group button:first-child {
      border-radius: 4px 0 0 4px !important;
      border-left: 1px solid #878787 !important; }
    .button-group button:last-child {
      border-radius: 0 4px 4px 0 !important; }

.card {
  display: inline-block;
  border-radius: 8px;
  overflow: hidden;
  word-wrap: break-word;
  position: relative;
  background-clip: border-box; }
  .card_image img {
    max-height: 200px;
    width: 100%;
    display: block; }
  .card_body {
    padding: 16px; }
    .card_body h1 {
      font-size: 28px;
      font-weight: 700; }
    .card_body p {
      font-size: 16px;
      font-weight: 400;
      line-height: 18px; }
      .card_body p:last-child {
        margin-bottom: 0; }
  .card_actions {
    display: flex;
    justify-content: flex-end;
    padding: 0 12px 16px; }
  @media screen and (max-width: 700px) {
    .card {
      width: 100% !important; } }

.modal {
  border-radius: 4px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999; }
  .modal_head {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 8px; }
    .modal_head h1 {
      font-size: 20px;
      font-weight: 700; }
  .modal_tagline {
    font-size: 12px;
    text-transform: uppercase;
    padding-bottom: 8px; }
  .modal_body {
    padding: 8px 16px 8px; }
  .modal_actions {
    display: flex;
    justify-content: flex-end;
    padding: 8px 16px 16px; }
  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 998; }
  .modal-full {
    width: 90%; }
